export enum InvoiceSource {
  EMP = 'EMP',
  CPO = 'CPO',
}

export interface Invoice {
  date: Date;
  reference: string;
  transaction_ids: string[];
  file_external_url?: string;
  file_id_to_download?: string;
  gross_amount?: number;
  net_amount?: number;
  currency?: string;
  customer_external_reference?: string;
  source: InvoiceSource;
}
