import React, { ReactElement } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { FormattedDate } from 'react-intl';

import { Assets } from '../../globals/configureAssets';
import { getVkwTypographyStyle, useVkwAssets, useVkwFormatMessage, VkwIconMore, VkwTheme } from '../../library';

const useStyles = makeStyles<VkwTheme, { clickable: boolean }>(theme => ({
  backgroundImage: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  cancelledBadge: {
    ...getVkwTypographyStyle('text12', theme),
    backgroundColor: theme.palette.grey[500],
    borderRadius: '10px',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  card: props => ({
    borderRadius: '6px',
    cursor: props.clickable ? 'pointer' : 'default',
    height: '224px',
    maxWidth: `calc(100vw - ${theme.spacing(6)}px)`,
    overflow: 'hidden',
    position: 'relative',
    width: '350px',
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${theme.spacing(6)}px)`,
    margin: theme.spacing(3),
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(6)}px)`,
  },
  header: {
    ...getVkwTypographyStyle('h8', theme),
    color: theme.palette.common.white,
    paddingRight: '20px',
  },
  info: {
    ...getVkwTypographyStyle('text14', theme),
    color: theme.palette.common.white,
    opacity: 0.6,
  },
  infoData: {
    ...getVkwTypographyStyle('text14', theme),
    color: theme.palette.common.white,
  },
  logo: {
    bottom: theme.spacing(2.5),
    height: 'auto',
    maxWidth: '115px',
    position: 'absolute',
    right: theme.spacing(4),
  },
  moreIcon: {
    color: theme.palette.common.white,
    opacity: 0.6,
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
  },
}));

interface TariffCardProps {
  contractAlias: string | null;
  vehicleType: string | null;
  vehicleLicensePlate: string | null;
  contractId: string | null;
  authenticationCardUid: string | null;
  onClick?: () => void;
  cancellationDate: Date | null;
}

export const TariffCard = ({
  authenticationCardUid,
  cancellationDate,
  contractAlias,
  contractId,
  onClick,
  vehicleLicensePlate,
  vehicleType,
}: TariffCardProps): ReactElement => {
  const styles = useStyles({ clickable: !!onClick });
  const assets = useVkwAssets<Assets>();
  const formatMessage = useVkwFormatMessage();

  return (
    <Box className={styles.card} onClick={() => onClick?.()}>
      <img className={styles.backgroundImage} src={assets.card} alt="TarifCardbackground" />
      {onClick && (
        <Box className={styles.moreIcon}>
          <VkwIconMore size={16} />
        </Box>
      )}
      <Box className={styles.content}>
        <Typography className={styles.header}>{contractAlias}</Typography>
        <Typography className={styles.info}>{vehicleType}</Typography>
        <Typography className={styles.info}>{vehicleLicensePlate}</Typography>
        <Box display="flex" height="100%">
          <Box flex={1} display="flex" flexDirection="column" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" marginBottom={1}>
              <Typography className={styles.info}>{formatMessage('ProductContractNumber')}:</Typography>
              <Typography className={styles.infoData}>{contractId ?? '-'}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography className={styles.info}>{formatMessage('ChargingContractUid')}:</Typography>
              <Typography className={styles.infoData}>{authenticationCardUid ?? '-'}</Typography>
            </Box>
          </Box>
          {cancellationDate && cancellationDate < new Date() && (
            <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end">
              <Typography className={styles.cancelledBadge}>
                {formatMessage('TariffCancelled')}&nbsp;
                <FormattedDate value={cancellationDate} year="numeric" month="2-digit" day="2-digit" />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <img className={styles.logo} src={assets.logoMonoWhite} alt="TarifCardLogo" />
    </Box>
  );
};
